import React from 'react'
import Layout from '../components/layout'
import Seo from '../components/seo'
import { Link } from 'gatsby'
import { FormattedMessage } from "react-intl"

import '../style/notfound.scoped.scss'

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not Found" />
    <div className="notfound-page">
      <i></i>
      <p><FormattedMessage id="notFound.desc" /></p>
      <Link to="/" className="return"><FormattedMessage id="notFound.return" /></Link>
    </div>
  </Layout>
)

export default NotFoundPage